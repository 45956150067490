import * as React from "react"
import Layout from "../components/Layout"
import { graphql } from 'gatsby'
import { GatsbyImage} from 'gatsby-plugin-image'
import styled from 'styled-components'
import { SEO } from "../components/Seo"

import { AnimationOnScroll } from 'react-animation-on-scroll';

import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox"
import {Container, Row, Col} from 'react-bootstrap'

//ROW
const GalleryPage = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 0px;
`
//COL
const ImageBoxStyled = styled.p`
  width: 50%;
  border: solid white 2px;
  ${({theme})=> theme.media.tablet}{
          width: 300px;
      }
`
const Title =styled.h1`
    font-weight: 100;
    text-align: center;
    margin: auto;
    padding: 40px 0px;
`

const Urdb24dz1 = ({data}) => { 
  return (
    <Layout>
      <Title>Ultra Race Dolina Bugu 2024 Dzień 1.</Title>
          <Container>
              <SimpleReactLightbox>
                <SRLWrapper>  
                  <Row>
                  {data.gallery.edges.map(({node}) =>(
                    <Col lg={4} md={6} key={node.id} className="py-3">
                      {/* <a href={node.publicURL}> */}
                        <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>
                          <a href={node.publicURL}>

                            <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt={node.base.split('_').join(' ').split('.')[0]} loading="lazy"/>
                          </a>
                          </AnimationOnScroll>
                      {/* </a> */}
                    </Col>  
                  ))}
                  </Row>
                </SRLWrapper>
              </SimpleReactLightbox>
          {/* </SRLWrapper> */}
          </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    gallery: allFile(filter: {relativeDirectory: {eq: "URDB24dz1"}}, , sort: {fields: name})  
    {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            gatsbyImageData(
              height: 600
              width: 900
              transformOptions: {fit: COVER}
              placeholder: BLURRED
              webpOptions: {quality: 50}

            )
          }
        }
      }
    }
  }`
// export const pageQuery = graphql`
//   query {
//     gallery: allFile(filter: {relativeDirectory: {eq: "gallery"}}, , sort: {fields: name})  
//     {
//       edges {
//         node {
//           id
//           base
//           publicURL
//           childImageSharp {
//             gatsbyImageData(
//               height: 400
//               width: 400
//               transformOptions: {fit: COVER}
//               placeholder: BLURRED
//               webpOptions: {quality: 50}

//             )
//           }
//         }
//       }
//     }
//   }`

export default Urdb24dz1

export const Head = () => (
  <SEO title="Galeria Ultra Race Dolina Bugu" description="Ultra Race Dolina Bugu to zawody gravelowe wdłuz jednej z ostanich dzikich rzek w Europie. Dwie trasy 260 i 550km"/>
)